import { Component } from '@angular/core';

import { BackendService } from '@core/services/backend.service';
import {
  DialogService,
  DynamicDialogRef,
  DynamicDialogComponent,
} from 'primeng/dynamicdialog';

import { AuthService } from '@core/services/auth.service';

import { DialogAddActionPlanComponent } from './components/dialog-add-action-plan/dialog-add-action-plan.component';

import {
  TableHeader,
  TableHeaderGroup,
  TableConfig,
} from '@shared/models/table.model';
import { Router } from '@angular/router';

@Component({
  templateUrl: './risks.component.html',
  styleUrls: ['./risks.component.scss'],
  providers: [DialogService],
})
export class RisksComponent {
  // Generic variables for table screens
  private SCREEN_NAME = 'risk';
  private SCREEN_NAME_POPUP = 'pop_up';

  // Table configuration
  riskTableConfig: TableConfig = {
    screenName: this.SCREEN_NAME,
    enableHeaderGroup: true,
    caption: 'Supplier Risk Evaluation',
    hasLoadMore: true,
    hasFilters: true,
    nrColumnsFreeze: 8,
    textInfo:
      "The risk calculation is only performed when all risk categories have been assessed. Exception: selection of 'phased out' or 'low risk /low pvo' in 'quickscan' column",
  };

  riskTableHeadersGroup: TableHeaderGroup[] = [];
  riskTableHeaders: TableHeader[] = [];
  riskTableData: any[] = [];
  tableFilters: any = {};
  riskTableFilters: any = {};
  riskTableDropdowns: any = {};

  // ---- Risks variables ----
  filter: any = {};
  currentPage = 0;
  isLoadingMore = false;
  dataLoading = true;
  totalRisks = 0;
  displayedRisks = 0;
  maxItemsPage: number = 100;
  permissions: any = {};

  // ---- Action Plans variables ----
  actionPlansFields: any[] = [];
  actionPlansDropdownValues: any[] = [];

  totalActionPlans: number = null;

  ref: DynamicDialogRef | undefined;

  constructor(
    private backendService: BackendService,
    private dialogService: DialogService,
    private router: Router,
    private authService: AuthService
  ) {
    this.filter = {
      page_num: this.currentPage,
      page_item_count: this.maxItemsPage,
      PVO_LOWER_LIMIT: null,
      PVO_UPPER_LIMIT: null,
      SORT_FIELD: null,
      SORT_ORDER: null,
    };
  }

  /**
   * Initializes the component:
   * - Registers user access.
   * - Fetches and configures frozen columns for the table.
   * - Retrieves table headers, groups, filters, and adds action plan columns.
   * - Merges table filters with existing filters and checks session storage for saved filters.
   * - Loads filter values, edit permissions, and table data.
   * - Fetches fields for action plan popup and dropdown values.
   */
  ngOnInit() {
    this.registerUserAccess();

    this.backendService
      .getFrozenColumns(this.SCREEN_NAME)
      .subscribe(
        (data) => (this.riskTableConfig.nrColumnsFreeze = data[0].N_Columns)
      );

    this.backendService
      .getTableHeaders(this.SCREEN_NAME)
      .subscribe(({ tableHeaders, tableHeaderGroups, tableFilters }) => {
        this.riskTableHeaders = tableHeaders;
        this.riskTableHeadersGroup = tableHeaderGroups;
        this.tableFilters = tableFilters;

        this.addColumnForActionPlans();

        //add combinig filters
        this.filter = { ...this.filter, ...tableFilters };
        let keysToRemove = ['BUSINESS_AREA', 'SUPPLIER_NAME', 'SEGMENT_NAME', 'LOCATION_NAME', 'BUSINESS_AREA', 'EXECUTION_UNIT'];
        keysToRemove.forEach(key => {
          delete tableFilters[key];
        });
        this.checkFiltersOnStorage();

        // get values for filters
        this.backendService
          .getFilterValues(this.SCREEN_NAME, tableFilters)
          .subscribe((data) => {
            this.riskTableFilters = data;
          });
        // get edit permissions
        this.backendService.getEditPermissions().subscribe((data) => {
          this.permissions = data;

          // get data and totals
          this.getRiskTableDataAndTotals();
        });
      });

    // get data for action plans popup
    this.backendService.getFieldsForActionPlanPopup().subscribe((data) => {
      this.actionPlansFields = data;
    });

    this.getDropdowns();
  }

  /**
   * Fetches dropdown values for:
   * - Action plans popup
   * - Risk table dropdowns
   */
  getDropdowns() {
    // get dropdown data for action plans popup
    this.backendService
      .getDropdownsValues(this.SCREEN_NAME_POPUP)
      .subscribe((data) => {
        this.actionPlansDropdownValues = data;
      });

    // get values for dropdowns
    this.backendService
      .getDropdownsValues(this.SCREEN_NAME)
      .subscribe((data) => {
        this.riskTableDropdowns = data;
      });
  }

  /**
   * Logs user access by sending a usage access request to the backend.
   */
  private registerUserAccess() {
    this.backendService
      .addUsageAccess()
      .subscribe((registerLog) => console.log('Register', registerLog));
  }

  /**
   * Fetches and updates risk table data and totals. Handles pagination and loading more data.
   * @param isLoadMore - Determines if additional data is being loaded for pagination.
   */
  getRiskTableDataAndTotals(isLoadMore: boolean = false) {
    this.currentPage = isLoadMore ? this.currentPage + 1 : 1;
    this.isLoadingMore = isLoadMore;
    this.filter.page_num = this.currentPage;
    this.dataLoading = true;


    if (!isLoadMore) {
      this.backendService.getRisksTotals(this.filter).subscribe((data) => {
        this.totalRisks = data;
      });
    }

    this.backendService.getActionPlansTotal(this.filter).subscribe((data) => {
      this.totalActionPlans = data;
    });

    this.backendService
      .getRisksTableData(this.filter, this.permissions)
      .subscribe((data) => {
        data = data.map((row) => {
          return {
            ...row,
          };
        });

        if (isLoadMore) {
          this.isLoadingMore = false;
          this.riskTableData = [...this.riskTableData, ...data];

        } else {
          this.backendService.getRisksTotals(this.filter).subscribe((data) => {
            this.totalRisks = data;
          });
          this.riskTableData = data;
        }

        this.displayedRisks = this.riskTableData.length;
        this.setSupportingInfo(data);
        this.dataLoading = false;
      });
  }

  /**
   * Loads filters from local storage if available, updating the current filter state,
   * and stores the updated filters back into local storage.
   */
  checkFiltersOnStorage() {
    // get values from session storage
    const sessionFilter = JSON.parse(
      localStorage.getItem('state-session-filters-' + this.SCREEN_NAME)
    );

    // if has filters or order saved, add to filters variable
    if (sessionFilter) {
      for (const key in this.filter) {
        this.filter[key] = sessionFilter[key];
      }
    }

    // always set a new object in case there is a new column added as filter
    localStorage.setItem(
      'state-session-filters-' + this.SCREEN_NAME,
      JSON.stringify(this.filter)
    );
  }

  /**
   * Updates the `riskTableHeaders` and `riskTableData` with supporting information.
   *
   * 1. Prepares a request body with unique `SEGMENT_CODE`, `LOCATION_CODE`, and `IFA_NUMBER` values.
   * 2. Fetches supporting information from the backend service.
   * 3. Updates `riskTableHeaders` to indicate if they have supporting information.
   * 4. Adds supporting information (KPI details) to corresponding rows in `riskTableData`.
   *
   * @param {Array} newData - The new data from which unique codes and numbers are extracted.
   */
  setSupportingInfo(newData) {
    const bodySupportingInfo = {
      SEGMENT_CODES: Array.from(
        new Set(newData.map((obj) => obj.SEGMENT_CODE))
      ).join(','),
      LOCATION_CODES: Array.from(
        new Set(newData.map((obj) => obj.LOCATION_CODE))
      ).join(','),
      IFA_NUMBERS: Array.from(
        new Set(newData.map((obj) => obj.IFA_NUMBER))
      ).join(','),
    };

    this.backendService
      .getSupportingInfo(bodySupportingInfo)
      .subscribe((data) => {
        // set hasSupportingInfo to each column on riskTableHeaders that has supporting info
        const dataMap = new Map(
          data.map((item) => [item['RISK_DB_KEY'], item])
        );
        this.riskTableHeaders.forEach((header) => {
          if (dataMap.has(header.id)) {
            header.hasSupportingInfo = true;
          }
        });

        data.forEach((info) => {
          // find row in dataArray that matches IFA_NUMBER, SEGMENT_CODE, LOCATION_CODE
          const matchingRow = this.riskTableData.find(
            (row) =>
              row['IFA_NUMBER'] === info.IFA_NUMBER &&
              row['SEGMENT_CODE'] === info.SEGMENT_CODE &&
              row['LOCATION_CODE'] === info.LOCATION_CODE
          );
          if (matchingRow) {
            // if supportingInfo doesn't exist, create it
            if (!matchingRow.supportingInfo) {
              matchingRow.supportingInfo = {};
            }
            // if supportingInfo[RISK_DB_KEY] doesn't exist, create it
            if (!matchingRow.supportingInfo[info.RISK_DB_KEY]) {
              matchingRow.supportingInfo[info.RISK_DB_KEY] = [];
            }
            // push the KPI_NAME and KPI_VALUE to the supportingInfo[RISK_DB_KEY] array
            matchingRow.supportingInfo[info.RISK_DB_KEY].push({
              KPI_NAME: info.KPI_NAME,
              KPI_VALUE: info.KPI_VALUE,
            });
          }
        });
      });
  }

  /**
   * Handles actions triggered on table rows.
   *
   * - For 'add' action: Opens a dialog to add a new action plan.
   * - For 'view' action: Navigates to the 'action-plans' page with specific query parameters.
   *
   * @param {Object} $event - The event object containing action type and row data.
   */
  handleRowAction($event) {
    if ($event.action === 'add') {
      this.openAddActionPlanDialog($event.row);
    }
    if ($event.action === 'view') {
      const queryParams = {
        IFA_NUMBER: $event.row.IFA_NUMBER,
        SEGMENT_CODE: '',
        LOCATION_CODE: '',
      };

      this.router.navigate(['action-plans'], { queryParams });
    }
  }

  fetchRelevantFilters($event){

    console.log('$event', $event)

    if(['BUSINESS_AREA', 'SUPPLIER_NAME', 'SEGMENT_NAME', 'LOCATION_NAME', 'BUSINESS_AREA', 'EXECUTION_UNIT'].includes($event) ){
      this.backendService.getRelevantFiltersValues($event, this.filter, this.riskTableConfig.screenName).subscribe(data => {
        console.log('data', data);
        this.riskTableFilters[$event] = data
      })
    }
  }

  /**
   * Adds an 'actions' column to the risk table headers for action buttons.
   *
   * - Adds a new column to the `riskTableHeaders` with buttons for 'add' and 'view' actions.
   * - Adds a corresponding header group entry for the 'actions' column.
   */
  addColumnForActionPlans() {
    // add one more column for the action buttons
    this.riskTableHeaders.push({
      id: 'actions',
      title: 'Action Plans',
      valueType: 'actions',
      tooltip: '1st number displays the number of all unfinished action plans for the supplier IFA#\n' +
        '2nd number displays the total number of all actions for the supplier IFA#',
      buttons: [
        {
          id: 'add',
          icon: 'fa fa-pencil',
          text: 'Add new',
          action: 'add',
          type: 'primary',
        },
        {
          id: 'view',
          icon: 'fa fa-trash',
          text: 'View',
          action: 'view',
          type: 'secondary',
        },
      ],
    });

    // add category column to the header group
    this.riskTableHeadersGroup.push({
      id: 'actions',
      title: 'ACTIONS',
      colspan: 1,
    });
  }

  /**
   * Opens a dialog for adding a new action plan.
   *
   * - Initializes a dialog with the provided `rowValues`.
   * - Configures the dialog with fields, dropdown values, and custom settings.
   * - Subscribes to the dialog's `onClose` event for post-dialog actions.
   * - Retrieves and sets the ARIA label for accessibility.
   *
   * @param {object} rowValues - The row data to pre-fill in the dialog.
   * @returns {DialogRef} - A reference to the opened dialog.
   */
  openAddActionPlanDialog(rowValues) {
    const ref = this.dialogService.open(DialogAddActionPlanComponent, {
      header: 'Add new Action Plan',
      dismissableMask: true,
      data: {
        actionPlansFields: this.actionPlansFields,
        rowData: rowValues,
        actionPlansDropdownValues: this.actionPlansDropdownValues,
      },
      width: '80%',
    });

    // Subscribe to the dialog's onClose event if you need to handle any actions when the dialog is closed
    ref.onClose.subscribe();

    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
    return ref;
  }

  /**
   * Lifecycle hook called when the component is destroyed.
   *
   * - Closes any open dialog references to prevent memory leaks.
   */
  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }

  /**
   * Updates the field or comment in the risk table based on the event data.
   *
   * - Sets the row as updating.
   * - Depending on the event type (`isComment`), either updates the risk comment or field.
   *
   * @param $event - Contains details about the update action, including:
   *   - `value`: New value to set.
   *   - `rowData`: Data of the row being updated.
   *   - `colId`: Column ID to update.
   *   - `rowIndex`: Index of the row being updated.
   *   - `columnData`: Additional column data.
   */
  updateField($event) {
    const newValue = $event.value;
    const rowData = $event.rowData;
    const columnIdToUpdate = $event.colId;
    const rowIndex = $event.rowIndex;
    const columnData = $event.columnData;

    this.riskTableData[$event.rowIndex].isUpdatingRow = true;

    if ($event.isComment) {
      this.updateRiskComment(newValue, rowData, columnIdToUpdate, rowIndex);
    } else {
      this.updateRiskField(
        newValue,
        rowData,
        columnIdToUpdate,
        rowIndex,
        columnData
      );
    }
  }

  /**
   * Updates a field in the risk table with new values.
   *
   * - Constructs a request body with the updated values and metadata.
   * - Sends a request to update the risk row.
   * - Updates the local risk table data with the response.
   * - Refreshes filter values for the updated column.
   *
   * @param newValue - The new value to set for the field.
   * @param rowData - The current data of the row being updated.
   * @param columnIdToUpdate - The ID of the column being updated.
   * @param rowIndex - The index of the row in the table data.
   * @param columnData - Additional data related to the column (e.g., catalog BK, used in calculations).
   */
  updateRiskField(newValue, rowData, columnIdToUpdate, rowIndex, columnData) {
    const body = {
      ...rowData,
      TITLE: '',
      AUTHOR: this.authService.getGIDAndAccoundName(),
      VALUE_TIMESTAMP: new Date()
        .toISOString()
        .substring(0, 23)
        .replace('T', ' '),
      RISK_DB_KEY: columnIdToUpdate,
      RISK_CATALOG_BK: columnData.catalogBK,
      RISK_VALUE: newValue,
      USED_IN_CALCULATIONS: columnData.usedInCalculations,
      TEST_RUN: 0,
    };

    // update value sent on the row we are updating
    body[columnIdToUpdate] = newValue;

    this.backendService.editRiskRow(body).subscribe((data) => {
      // save action plan count to add again
      const actionPlanCount = this.riskTableData[rowIndex].ACTION_PLAN_COUNT;
      // update the properties of the this.riskTableData[rowIndex] object with the properties of the data[0] object without creating a new reference.

      this.riskTableData[rowIndex] = {
        ...this.riskTableData[rowIndex],
        ...data[0],
      };
      this.riskTableData[rowIndex].ACTION_PLAN_COUNT = actionPlanCount;

      this.riskTableData[rowIndex].isUpdatingRow = false;

      this.backendService
        .getFilterValues(
          this.SCREEN_NAME,
          { [columnIdToUpdate]: null },
          '',
          true
        )
        .subscribe((data) => {
          this.riskTableFilters[columnIdToUpdate] = data[columnIdToUpdate];
        });
    });
  }

  /**
   * Updates the comment field of a risk row.
   *
   * - Constructs a request body with the new comment and metadata.
   * - Sends a request to save the comment.
   * - Updates the local risk table data with the new comment.
   *
   * @param newValue - The new comment to set.
   * @param rowData - The current data of the row being updated.
   * @param columnIdToUpdate - The ID of the column being updated (should be 'COMMENTS').
   * @param rowIndex - The index of the row in the table data.
   */
  updateRiskComment(newValue, rowData, columnIdToUpdate, rowIndex) {
    const body = {
      SEGMENT_CODE: rowData.SEGMENT_CODE,
      LOCATION_CODE: rowData.LOCATION_CODE,
      IFA_NUMBER: rowData.IFA_NUMBER,
      COMMODITY: rowData.COMMODITY,
      ID_UNIQUE_RISK: rowData.ID_UNIQUE_RISK,
      COMMENTS: newValue,
      TITLE: '',
      AUTHOR: this.authService.getGIDAndAccoundName(),
      VALUE_TIMESTAMP: new Date()
        .toISOString()
        .substring(0, 23)
        .replace('T', ' '),
    };

    this.backendService.setComment(body).subscribe(() => {
      this.riskTableData[rowIndex][columnIdToUpdate] = newValue;
      this.riskTableData[rowIndex].isUpdatingRow = false;
    });
  }

  /**
   * Updates the filter criteria based on the provided event data.
   *
   * - Clears filter and order if no event data is provided.
   * - Updates specific filter criteria based on event type:
   *   - **Sorting**: Sets `SORT_FIELD` and `SORT_ORDER`.
   *   - **PVO (Price Value Option)**: Sets `PVO_LOWER_LIMIT` and `PVO_UPPER_LIMIT`.
   *   - **Other**: Sets filters for other columns based on event data.
   * - Stores the updated filter state in session storage.
   * - Refreshes the risk table data and totals.
   *
   * @param $event - The event object containing filter updates.
   *                 May include properties like `isSort`, `isPVO`, `colId`, `order`, `minValue`, `maxValue`, and `valuesList`.
   */
  updateFilter($event) {
    //clear filter and order
    if (!$event) {
      const keepKeys = ['page_item_count', 'page_num'];
      Object.keys(this.filter).forEach((key) => {
        if (!keepKeys.includes(key)) {
          this.filter[key] = null;
        }
      });
    } else {
      if ($event.isSort) {
        this.filter.SORT_FIELD = $event.colId;
        this.filter.SORT_ORDER = $event.order;
      } else if ($event.isPVO) {
        this.filter.PVO_LOWER_LIMIT = $event.minValue ? $event.minValue : null;
        this.filter.PVO_UPPER_LIMIT = $event.maxValue ? $event.maxValue : null;
      } else {
        this.filter[$event.colId] = $event.valuesList;
      }
    }

    // update value on session storage
    localStorage.setItem(
      'state-session-filters-' + this.SCREEN_NAME,
      JSON.stringify(this.filter)
    );

    // update riskTableData
    this.getRiskTableDataAndTotals();
  }
}
