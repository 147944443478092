import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'se-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarComponent {
  @Input() date: Date;
  @Input() isDisabled: boolean;
  @Input() defaultDate: Date | null;
  @Output() changedDate: EventEmitter<Date> = new EventEmitter<Date>();
  faCalendar = faCalendar;

  constructor(private router: Router) {}

  clearDate() {
    this.date = this.defaultDate;
    this.changedDate.emit(this.date);
  }

  /**
   * Handles the date change event and emits the new date.
   * @param {Date} date - The selected date.
   */
  onDateChange(date: Date) {
    if (date !== null) {
      this.date = date;
      this.changedDate.emit(date);
    } else {
      this.clearDate();
    }
  }
}
